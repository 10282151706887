import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie } from '@/facade'


Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'selected',
  routes: [
    {
      path: "/login",
      name: 'login',
      component: () => import('@/views/Login.vue'),
      redirect: { path: '/login_content',},
      children:[
        { path: "/reg", name: 'reg', component: () => import('@/views/Reg.vue') },
        { path: "/login_content", name: 'login_content', component: () => import('@/views/LoginContent.vue') },
        { path: "/password", name: 'password', component: () => import('@/views/Password.vue') },
      ]
    },
    {
      path: "/net_layout",
      name: 'home',
      meta: { requiresAuth: true },
      component: () => import( '@/views/NetLayout.vue'),
      redirect:'/netDetail',
      children: [
         { path: "/nodeGroup", name: 'nodeGroup', meta: { requiresAuth: true }, component: () => import('@/views/pages/NodeGroup.vue') },
         { path: "/netDetail", name: 'netDetail', meta: { requiresAuth: true }, component: () => import('@/views/pages/NetDetail.vue') },
         { path: "/equipment", name: 'equipment', meta: { requiresAuth: true }, component: () => import('@/views/pages/Equipment.vue') },
         { path: "/orderList", name: 'orderList', meta: { requiresAuth: true }, component: () => import('@/views/pages/OrderList.vue') },
         { path: "/accountList", name: 'accountList', meta: { requiresAuth: true }, component: () => import('@/views/pages/Account.vue') },
         { path: "/node", name: 'node', meta: { requiresAuth: true }, component: () => import('@/views/pages/Node.vue') },
         { path: "/install", name: 'install', meta: { requiresAuth: true }, component: () => import('@/views/pages/Install.vue') },
        
        ]
    },
    {
      path: "/",
      name: 'home',
      meta: { requiresAuth: true },
      component: () => import( '@/views/Layout.vue'),
      redirect:to=>{
        console.log('1234')
        const scene=localStorage.getItem('scene')
        if(scene&&scene=='content') return { path: '/netDetail' }
        else return { path: '/home' }
      },
      children: [
         { path: "/collection", name: 'collection', meta: { requiresAuth: true }, component: () => import('@/views/pages/Collection.vue') },
         { path: "/home", name: 'home', meta: { requiresAuth: true }, component: () => import('@/views/pages/Home.vue') },
         { path: "/userList", name: 'userList', meta: { requiresAuth: true }, component: () => import('@/views/pages/UserList.vue') },
         { path: "/network", name: 'network', meta: { requiresAuth: true }, component: () => import('@/views/pages/Network.vue') },
         { path: "/json", name: 'json', meta: { requiresAuth: true }, component: () => import('@/views/pages/Json.vue') },
         { path: "/upPass", name: 'upPass', meta: { requiresAuth: true }, component: () => import('@/views/pages/UpPassword.vue') },
         { path: "/adminNet", name: 'adminNet', meta: { requiresAuth: true }, component: () => import('@/views/pages/AdminNetWork.vue') },
         { path: "/key", name: 'key', meta: { requiresAuth: true }, component: () => import('@/views/pages/Key.vue') },
         { path: "/net_detail_add", name: 'netDetailAdd', meta: { requiresAuth: true }, component: () => import('@/views/pages/NetDetailAdd.vue') },
      
        ]
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {  
    if (!getCookie('token')) {
      //  console.log(getCookie('token'))
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})