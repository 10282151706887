import Vue from 'vue'
import App from './App.vue'
import {router} from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Toasted from 'vue-toasted';
import jshint from "jshint";
import { codemirror } from "vue-codemirror";
import moment from 'moment'
import VueClipboard  from 'vue-clipboard2'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import VeLine from 'v-charts/lib/line.common'
Vue.component(VeLine.name, VeLine)

Vue.component('vselect', vSelect)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)


//时间范围选择
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
Vue.component('date-picker',DatePicker)
import "codemirror/lib/codemirror.css";
Vue.use(codemirror);
Vue.prototype.$moment=moment

window.JSHINT = jshint.JSHINT;
Vue.config.productionTip = false
Vue.use(Toasted)
export const vm=new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

