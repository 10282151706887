<template>
    <div>
      <router-view></router-view>
    </div>
</template>

<script>


  export default {
    name: 'App',
    data: () => ({
      //
    }),
  };
</script>
<style>
 .v-pagination{
   justify-content:left!important
 }
   .vs__dropdown-menu{
     height: 150px!important;
    padding:0px!important;
  }
  .container{
    max-width:100%!important
  }
</style>