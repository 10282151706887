import axios from 'axios'
import { vm } from './main'
// import SecurityService from './security_service'

export const domain = 'v2.beikevip8.com';


// const service = new SecurityService('0#rJo&T3HONF@DOl', { baseURL: document.location.protocol + '//' + domain, withCredentials: true, timeout: 10000 });
// export async function post(url, data) {
//   const config = {};
//   if (obj.token) config.headers = { 'Authorization': obj.token };
//   return await service.post(url, data, config);
// }

const service = axios.create({ baseURL: document.location.protocol + '//' + domain,withCredentials:true});

export async function post(url, data) {
  try {
    const config = {};
    if (obj.token) config.headers = { 'Authorization': obj.token };
    let res = await service.post(url, data, config);
    return res.data
  } catch (e) {
    // console.log(e)
    let res = e.response;
    if (res.data.message == 'unauthorized') {
      res.data.message = '验证未通过，请重新登陆'
      tips('danger', res.data.message)
      vm.$router.replace({ path: '/login' })
    }
    return { code: 'error', status: res && res.status, message: (res && res.data && res.data.message) || e.message };
  }
}



//延时操作
export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

//设置cookie
export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
}

//获取cookie
export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
  }
  return "";
}
//清除cookie  
export function clearCookie(name) {
  setCookie(name, '', -1);
}

export function transferTime(value) {
  if (value) return vm.$moment(value).format("YYYY-MM-DD HH:mm:ss")
}



export function tips(variant, message) {
  vm.$toasted.show(message, {
    type: variant,
    theme: "bubble",
    position: "top-right",
    duration: 3000,
    singleton: true
  })
}


export const obj = {
  token: getCookie('token'),
  uid: getCookie('uid')
}
export const limits = [
  { value: 10, text: '10条/每页' },
  { value: 20, text: '20条/每页' },
  { value: 30, text: '30条/每页' },
  { value: 40, text: '40条/每页' },
  { value: 50, text: '50条/每页' },
  { value: 60, text: '60条/每页' },
  { value: 70, text: '70条/每页' },
  { value: 80, text: '80条/每页' },
  { value: 90, text: '90条/每页' },
  { value: 100, text: '100条/每页' },
]