import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)
let items = [
  { icon: 'mdi-home', text: '首页', url: "/home", isShow: true },
  // { icon: 'mdi-antenna', text: '虚拟网', url: "/home", isShow: true },
  { icon: 'mdi-shield-key', text: '密钥管理', url: "/key", isShow: true },
  // { icon: 'mdi-chevron-up', 
  //   'icon-alt': 'mdi-chevron-down',
  //   text: '虚拟网管理', 
  //   model: false,
  //   isShow: true, 
  //   children: [],
  // },
  // {
  //   icon: 'mdi-chevron-up',
  //   'icon-alt': 'mdi-chevron-down',
  //   text: '平台管理',
  //   model: false,
  //   isShow: true,
  //   children: [
  //     { icon: 'mdi-chevron-up', text: '用户管理', url: "/userList", isShow: true },
  //     // { icon: 'mdi-chevron-up', text: '虚拟网管理', url: "/adminNet", isShow: true },
  //   ],
  // },

]
export default new Vuex.Store({
  state: {
    items,
    nodeList: [],
    groupList: [],
    robotList:[],
    roles:'',
    ips:[],
  },
  mutations: {

    changeStatus(state, payload) {
      state.items = payload
    },
    changeNodeList(state, payload) {
      state.nodeList = payload
    },
    changeGroupList(state, payload) {
      state.groupList = payload
    },
    changeRobotList(state, payload) {
      state.robotList = payload
    },
    changeRole(state,role){
      state.roles=role
    },
    changeIps(state,payload){
      state.ips=payload
    },

  },
  actions: {

  },
  modules: {}
})